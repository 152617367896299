<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">订单信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="订单状态">
					{{ detail.order_data.status==0?'未发货':(detail.order_data.status==1)?'部分发货':detail.order_data.status==2?'已完成':'已取消' }}
				</a-descriptions-item>
				<a-descriptions-item label="下单人">
					{{ detail.order_data.username }}
				</a-descriptions-item>
				<a-descriptions-item label="订单编号">
					{{ detail.order_data.order_no }}
				</a-descriptions-item>
				<a-descriptions-item label="下单时间">
					{{ detail.order_data.create_time }}
				</a-descriptions-item>
				<a-descriptions-item label="下单客户">
					{{ detail.order_data.customer_name }}
				</a-descriptions-item>
				<a-descriptions-item label="订单类型">
					{{ detail.order_data.type==1?'现结订单':'预付款订单' }}
				</a-descriptions-item>
				<a-descriptions-item label="上级配送商">
					{{ detail.order_data.distributor_name }}
				</a-descriptions-item>
				<a-descriptions-item label="收货地址">
					{{ detail.order_data.receiver_province+detail.order_data.receiver_city+detail.order_data.receiver_area+detail.order_data.receiver_address }}
				</a-descriptions-item>
				<a-descriptions-item label="提货结束时间">
					{{ detail.order_data.end_time }}
				</a-descriptions-item>
				<a-descriptions-item label="收货人">
					{{ detail.order_data.receiver }}
				</a-descriptions-item>
				<a-descriptions-item label="联系电话">
					{{ detail.order_data.receiver_phone }}
				</a-descriptions-item>
				<a-descriptions-item label="备注">
					{{ detail.order_data.remarks }}
				</a-descriptions-item>
			</a-descriptions>

			<a-divider orientation="left" v-if='detail.uncheckout_data.length>0'>未出库商品信息</a-divider>
			<div class="li_item" v-if='detail.uncheckout_data.length>0'>
				<div class="arpproval_title">
					<span class="department_appr">商品名称</span>
					<span class="department_appr">类型</span>
					<span class="department_appr">数量</span>
					<span class="department_appr">金额</span>
				</div>
				<div class="arpproval_title arpproval_title1" v-for="(tan,index) in detail.uncheckout_data" :key='index'>
					<span class="department_appr">{{tan.goods_title}}</span>
					<span class="department_appr">{{tan.type==1?'本品':(tan.type==2?'促销':(tan.type==3?'搭赠':'提货奖励'))}}</span>
					<span class="department_appr">{{tan.num}}</span>
					<span class="department_appr">{{tan.pay_total}}元</span>
				</div>
				<div class="all_money_info">
					<span>数量总计:{{detail.uncheckout_num}}</span>  <span>金额总计:{{detail.uncheckout_total}}</span>
				</div>
			</div>

			<a-divider orientation="left" v-if='detail.checkout_data.length>0'>已出库商品信息</a-divider>
			<div v-if='detail.checkout_data.length>0'>
				<div v-for="(item,index) in detail.checkout_data" :key="index">
					<a-descriptions :column="2">
						<a-descriptions-item label="出库单号">
							{{ item.checkout_no }}
						</a-descriptions-item>
						<a-descriptions-item label="拆单时间">
							{{ item.create_time }}
						</a-descriptions-item>
						<a-descriptions-item label="出库单状态">
							{{ item.status==-1?'已取消':(item.status==0?'待接单':(item.status==1?'待配送':item.status==2?'待收货':"已完成")) }}
						</a-descriptions-item>
						<a-descriptions-item label="配送时间">
							{{ item.checkout_time }}
						</a-descriptions-item>
						<a-descriptions-item label="上级配送商">
							{{ item.distributor_name }}
						</a-descriptions-item>
						<a-descriptions-item label="收货时间">
							{{ item.receiving_time }}
						</a-descriptions-item>
						<a-descriptions-item label="出库商品">
							{{ item.subsidy_time}}
						</a-descriptions-item>
					</a-descriptions> 
					<div class="li_item" style="border-bottom:1px solid #e5e5e5;padding-bottom:60px;">
						<div class="arpproval_title">
							<span class="department_appr">商品名称</span>
							<span class="department_appr">类型</span>
							<span class="department_appr">数量</span>
							<span class="department_appr">金额</span>
						</div>
						<div class="arpproval_title arpproval_title1" v-for="(tan,index) in item.goods" :key='index'>
							<span class="department_appr">{{tan.goods_title}}</span>
							<span class="department_appr">{{tan.type==1?'本品':(tan.type==2?'促销':(tan.type==3?'搭赠':'提货奖励'))}}</span>
							<span class="department_appr">{{tan.num}}</span>
							<span class="department_appr">{{tan.pay_total}}元</span>
						</div>
						<div class="all_money_info" >
							<span>数量总计:{{item.goods_num}}</span>  <span >金额总计:{{item.pay_price}}元</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 操作日志 -->
			<a-divider orientation="left" v-if="detail.operation" >操作日志</a-divider>
			<div class="content">
				<div class="item" v-if="detail.operation">
					<div class="left"></div>
					<div class="right">
						<div class="time_line">
							<a-timeline>
								<a-timeline-item  v-for="(item,index) in detail.operation" :key="index">
									<div class="title">{{item.content}}</div>
									<div class="desc">{{'时间：' + item.operator_time}}</div>
									<div class="desc">{{'操作人：' + item.operator_name}}</div>
								</a-timeline-item>
							</a-timeline>
						</div>
					</div>
				</div>
			</div>
			<!--  -->
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	import LookImages from "@/components/LookImages";
	import {
		orderInfo_new
	} from '@/api/order.js'
	import Avatar from "@/components/small/Avatar";
	export default {
		components: {
			LookImages,
			Avatar
		},
		data() {
			return {
				detail: {
					operation:[],
                    order_data:{},
					checkout_data: [],
					checkout_data:[]
				}
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			get_info() {
				orderInfo_new({
					data: {
						id: this.$route.query.id,
					}

				}).then(res => {
					this.detail = res.data.data
					console.log('this.detail',this.detail)
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 600px;
	}

	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		width: 100%;
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}
		margin-bottom: 30px;
	}
	.arpproval_title {
		padding: 15px 0;
		
		text-align: center;
		background: rgba(245, 245, 245, 1);
		font-size: 14px;
		span{
			width:6%;
			display: inline-block;
		}
		.department_appr {
			width: 25%;
		}
	}
	.arpproval_title1 {
		height: auto;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
	}
	.go_see {
		color: #1890ff;
	}
    .all_money_info {
        margin-left: 80%;margin-top: 20px;
        font-size: 14px;
        span:nth-child(1){
            display: inline-block;
            margin-right: 10px;
        }
    }
</style>
